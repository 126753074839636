import type { ThunkAction } from '../../types';
import type { Restaurant, Page } from '../model/types';
import type { AxiosPromise } from 'axios';
import * as types from './types';
import * as api from '../api';
import listHelper from '../../utility/general/actionCreators/listHelper';
import type { OrderDirection } from '../../../utility/types';

export const reset = () => ({
  type: types.RESET,
});

export const resetMap = () => {
  return {
    type: types.RESET_MAP,
  };
};

export const unsetMapInvalidateID = () => {
  return {
    type: types.UNSET_MAP_INVALIDATE_ID,
  };
};

export const get =
  (marketId: string, restaurantId: string): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.GET,
      payload: api
        .get(marketId, restaurantId)
        .then((response) => response.data),
    });
  };

export const put =
  (marketId: string, restaurant: Restaurant): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.PUT,
      payload: api.put(marketId, restaurant).then((response) => response.data),
    });
  };

export const post =
  (marketId: string, restaurant: Restaurant): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.POST,
      payload: api.post(marketId, restaurant).then((response) => response.data),
    });
  };

export const remove =
  (marketId: string, restaurantId: string): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.REMOVE,
      payload: api.remove(marketId, restaurantId).then(() => ({
        id: restaurantId,
      })),
    });
  };

export const listSortedBy =
  (marketId: string, sortBy: string): ThunkAction =>
  (dispatch) => {
    dispatch(setListSortedBy(sortBy));
    dispatch(
      list(marketId, {
        append: false,
        invalidate: true,
        debounce: { duration: 400 },
      })
    );
  };

export const setListSortedBy =
  (sortBy: string): ThunkAction =>
  (dispatch) => {
    dispatch({
      type: types.SET_SORT_BY,
      payload: sortBy,
    });
  };

export const listWithSortOrder =
  (marketId: string, sortOrder: OrderDirection): ThunkAction =>
  (dispatch) => {
    dispatch(setListSortOrder(sortOrder));
    dispatch(
      list(marketId, {
        append: false,
        invalidate: true,
        debounce: { duration: 400 },
      })
    );
  };

export const setListSortOrder =
  (sortOrder: OrderDirection): ThunkAction =>
  (dispatch) => {
    dispatch({
      type: types.SET_SORT_ORDER,
      payload: sortOrder,
    });
  };

export const setSearchQuery =
  (searchQuery: string | undefined): ThunkAction =>
  (dispatch) => {
    dispatch({
      type: types.SET_SEARCH_QUERY,
      payload: searchQuery,
    });
  };

export const setDisplayedLocale =
  (language: string | undefined): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.SET_DISPLAYED_LOCALE,
      payload: language,
    });
  };

export const cancelAllListRequests = (): ThunkAction => (dispatch) => {
  return dispatch({
    type: types.LIST_CANCEL_ALL,
  });
};

interface ListOptions {
  getNextPage?: boolean;
  append?: boolean;
  invalidate?: boolean;
  debounce?: {
    key?: string;
    duration: number;
  };
  fetchAll?: boolean;
  pageSize?: number;
}

interface ListConfig {
  fetch: {
    action: string;
    bindFetch: (market: string, nextPageToken: string) => AxiosPromise<Page>;
  };
  subject: string;
  statePath: string;
  getNextPage?: boolean;
  append?: boolean;
  debounce?: {
    key?: string;
    duration: number;
  };
  invalidate?: {
    actionSet: string;
    actionClear: string;
  };
  fetchAll?: boolean;
  pageSize?: number;
}

export const list =
  (market: string, options?: ListOptions): ThunkAction =>
  (dispatch, getState) => {
    const { restaurants } = getState();
    const { sortBy: orderBy, sortOrder, searchQuery } = restaurants;

    const { invalidate, pageSize, ...restOptions } = options ?? {};

    let cfg: ListConfig = {
      ...restOptions,
      fetch: {
        action: types.LIST,
        bindFetch: (market: string, nextPageToken: string) =>
          api.list(market, {
            nextPageToken,
            orderBy,
            sortOrder,
            searchQuery,
            pageSize,
          }),
      },
      subject: market,
      statePath: 'restaurants',
    };

    if (options?.invalidate) {
      cfg.invalidate = {
        actionSet: types.SET_MAP_INVALIDATE_ID,
        actionClear: types.UNSET_MAP_INVALIDATE_ID,
      };
    }

    return dispatch(listHelper(cfg));
  };

export const importRestaurants =
  (marketId: string, restaurants: Object): ThunkAction =>
  (dispatch) => {
    return dispatch({
      type: types.IMPORT,
      payload: api.importRestaurants(marketId, restaurants),
    });
  };

export const fetchRpmConfig =
  (marketId: string): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();

    if (typeof state.restaurants.rpmMode[marketId] === "string") {
      return;
    }

    api.getRpmConfig(marketId)
      .then((res) => {
        dispatch({
          type: types.SET_RPM_MODE,
          payload: {
            market: res.data?.market ?? marketId,
            mode: res.data?.mode?.toLowerCase() ?? "disabled",
          },
        });
      })
      .catch(() => {
        dispatch({
          type: types.SET_RPM_MODE,
          payload: {
            market: marketId,
            mode: "disabled",
          },
        });
      });
  };
